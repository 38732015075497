var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('p',{staticClass:"tit",staticStyle:{"color":"#D9EFFF"}},[_vm._v("用户统计")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"group-flex mb40"},[_c('div',[_c('p',{staticClass:"data-tit"},[_vm._v("总登录人次（次）")]),_c('p',{staticClass:"data-val"},[_vm._v(_vm._s(_vm.userData.registerAmount))])]),_c('div',[_c('p',{staticClass:"data-tit"},[_vm._v("服务时长累计（分）")]),_c('p',{staticClass:"data-val"},[_vm._v(_vm._s(_vm.userData.serviceAmount))])]),_c('div',[_c('p',{staticClass:"data-tit"},[_vm._v("人均服务人次（人）")]),_c('p',{staticClass:"data-val"},[_vm._v(_vm._s(_vm.userData.serviceTimes))])])]),_c('div',{staticClass:"group-flex"},[_c('div',{staticClass:"d-flex algin-end"},[_c('div',[_c('p',{staticClass:"mb26"},[_vm._v("同比上周")]),_c('div',{staticClass:"d-flex"},[_c('img',{attrs:{"src":require("../../assets/imgs/arrow-up.png"),"width":"24px","height":"24px","alt":""}}),_c('p',{staticClass:"number-up ml8"},[_vm._v(_vm._s(_vm.userData.registerAmountRatio)+"%")])])]),_c('div',{staticClass:"line-data-wrap"},_vm._l((_vm.weekData1.data),function(v,i){return _c('div',{key:i,staticClass:"line-item",style:({
              background: _vm.weekData1.up ? '#25D94B' : '#FC743E',
              height: v * 6 + 'px',
            })})}),0)]),_c('div',{staticClass:"d-flex algin-end"},[_c('div',[_c('p',{staticClass:"mb26"},[_vm._v("同比上周")]),_c('div',{staticClass:"d-flex algin-center"},[_c('img',{attrs:{"src":require("../../assets/imgs/arrow-up.png"),"width":"24px","height":"24px","alt":""}}),_c('p',{staticClass:"number-up ml8"},[_vm._v(_vm._s(_vm.userData.serviceAmountRatio)+"%")])])]),_c('div',{staticClass:"line-data-wrap"},_vm._l((_vm.weekData2.data),function(v,i){return _c('div',{key:i,staticClass:"line-item",style:({
              background: _vm.weekData2.up ? '#25D94B' : '#FC743E',
              height: v * 6 + 'px',
            })})}),0)]),_c('div',{staticClass:"d-flex algin-end"},[_c('div',[_c('p',{staticClass:"mb26"},[_vm._v("同比上周")]),_c('div',{staticClass:"d-flex algin-center"},[_c('img',{attrs:{"src":require("../../assets/imgs/arrow-down.png"),"width":"24px","height":"24px","alt":""}}),_c('p',{staticClass:"number-down ml8"},[_vm._v(_vm._s(_vm.userData.serviceTimesRation)+"%")])])]),_c('div',{staticClass:"line-data-wrap"},_vm._l((_vm.weekData3.data),function(v,i){return _c('div',{key:i,staticClass:"line-item",style:({
              background: _vm.weekData3.up ? '#25D94B' : '#FC743E',
              height: v * 6 + 'px',
            })})}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }