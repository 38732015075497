<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <div class="main">
        <!-- <div class="title">
          健康驿站数据大屏
        </div> -->
        <!-- 用户服务统计 -->
        <div class="box1">
          <ServiceStatistics />
        </div>
        <!-- 运动器械使用日活 -->
        <div class="box2">
          <UsingDaily />
        </div>
        <!-- 覆盖量统计 -->
        <div class="box3">
          <CoverageStatistics />
        </div>
        <!-- 检测数据 -->
        <div class="box4">
          <TestData />
        </div>
        <!-- 用户画像 -->
        <div class="box5">
          <UserPortrait />
        </div>
        <!-- 用户数据统计 -->
        <div class="box6">
          <UserData />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import drawMixin from "../utils/drawMixin";
import CoverageStatistics from "../components/pages/coverageStatistics";
import ServiceStatistics from "../components/pages/serviceStatistics";
import TestData from "../components/pages/testData";
import UserData from "../components/pages/userData";
import UserPortrait from "../components/pages/userPortrait";
import UsingDaily from "../components/pages/usingDaily";

export default {
  components: {
    CoverageStatistics,
    ServiceStatistics,
    TestData,
    UserData,
    UserPortrait,
    UsingDaily,
  },
  mixins: [drawMixin],
  data() {
    return {
      currentTab: "page1",
    };
  },
  mounted() {
    // 每分钟刷新一次页面
    this.refreshInterval = setInterval(() => {
      location.reload();
    }, 60000);
  },
  beforeDestroy() {
    // 清除定时器以避免内存泄漏
    clearInterval(this.refreshInterval);
  },
  methods: {},
};
</script>

  
<style lang="scss" scoped>
@import "../assets/scss/index.scss";

.main {
  width: 100%;
  height: 100%;
  position: relative;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  padding: 20px 0;
  background: rgba(15, 30, 68, 0.6);
  border-bottom: 1px solid #99ccff;
}

.box1 {
  width: 941px;
  height: 337px;
  background: rgba(15, 30, 68, 0.4);
  border-radius: 4px;
  border: 1px solid #99ccff;
  position: absolute;
  left: 0;
  margin-right: 14px;
  // box-shadow: 0px 0px 10px #ffffff inset;
}

.box2 {
  width: 937px;
  height: 484px;
  background: rgba(15, 30, 68, 0.4);
  border-radius: 4px;
  border: 1px solid #99ccff;
  position: absolute;
  right: 0;
  // box-shadow: 0px 0px 10px #ffffff inset;
}

.box3 {
  width: 940px;
  height: 374px;
  background: rgba(15, 30, 68, 0.4);
  border-radius: 4px;
  border: 1px solid #99ccff;
  position: absolute;
  top: 351px;
  // box-shadow: 0px 0px 10px #ffffff inset;
}

.box4 {
  width: 521px;
  height: 228px;
  background: rgba(15, 30, 68, 0.4);
  border-radius: 4px;
  border: 1px solid #99ccff;
  position: absolute;
  left: 954px;
  top: 498px;
  // box-shadow: 0px 0px 10px #ffffff inset;
}

.box5 {
  width: 402px;
  height: 552px;
  background: rgba(15, 30, 68, 0.4);
  border-radius: 4px;
  border: 1px solid #99ccff;
  position: absolute;
  right: 0;
  top: 498px;
  // box-shadow: 0px 0px 10px #ffffff inset;
}

.box6 {
  width: 1474px;
  height: 310px;
  background: rgba(15, 30, 68, 0.6);
  border-radius: 4px;
  border: 1px solid #99ccff;
  position: absolute;
  left: 0;
  bottom: 0;
  // box-shadow: 0px 0px 10px #ffffff inset;
}
</style>
