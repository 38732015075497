<template>
    <div class="main">
      <p class="tit" style="color: #D9EFFF;">用户画像</p>
      <div class="echart-wrap gender-wrap mb20">
        <p class="g-tit mb20">性别分布</p>
        <div id="g-echarts" style="width: 100%; height: 130px" />
      </div>
      <div class="echart-wrap age-wrap">
        <p class="g-tit mb20">年龄分布</p>
        <div id="a-echarts" style="width: 100%; height: 230px" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HealthUserPortrait",
    data() {
      return {
        drawChart() {
          const myChart = this.$echarts.init(
            document.getElementById("g-echarts")
          );
          const option = {
            legend: {
              top: "bottom",
              textStyle: {
                color: "#fff",
              },
            },
            series: [
              {
                type: "pie",
                center: ["50%", "40%"],
                data: [
                  { value: 22, name: "男" },
                  { value: 18, name: "女" },
                ],
                itemStyle: {
                  normal: {
                    color: function (colors) {
                      var colorList = ["#00D98B", "#228FFE"];
                      return colorList[colors.dataIndex];
                    },
                  },
                },
              },
            ],
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
        drawAgeChart() {
          const myChart = this.$echarts.init(
            document.getElementById("a-echarts")
          );
          const option = {
            grid: {
              left: "10%",
              top: "10%",
              right: "10%",
              bottom: "10%",
            },
            xAxis: [
              {
                type: "category",
                data: ["60岁以下", "60-80岁", "80岁以上"],
                axisLine: {
                  // x轴线的颜色以及宽度
                  show: true,
                  lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid",
                  },
                },
                axisLabel: {
                  // x轴文字的配置
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisLine: {
                  // x轴线的颜色以及宽度
                  show: true,
                  lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid",
                  },
                },
                axisLabel: {
                  // x轴文字的配置
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              {
                type: "value",
                axisLine: {
                  // x轴线的颜色以及宽度
                  show: true,
                  lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid",
                  },
                },
                axisLabel: {
                  // x轴文字的配置
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
            series: [
              {
                name: "Evaporation",
                type: "bar",
                tooltip: {
                  valueFormatter: function (value) {
                    return value + " ml";
                  },
                },
                data: [100, 444, 332],
                itemStyle: {
                  normal: {
                    color: "#228FFE",
                  },
                },
              },
              {
                name: "Precipitation",
                type: "bar",
                tooltip: {
                  valueFormatter: function (value) {
                    return value + " ml";
                  },
                },
                data: [100, 433, 554],
                itemStyle: {
                  normal: {
                    color: "#00D98B",
                  },
                },
              },
            ],
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        },
      };
    },
  
    mounted() {
      this.drawChart();
      this.drawAgeChart();
    },
  
    methods: {},
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 30px;
    }
  
    .echart-wrap {
      .g-tit {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  </style>
  