<template>
    <div class="main">
      <p class="tit" style="color: #D9EFFF;">用户数据统计</p>
      <div
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <div style="width: 500px; height: 230px">
          <dv-charts ref="chart1" :option="option1" />
        </div>
        <div style="width: 500px; height: 230px">
          <dv-charts :option="option2" />
        </div>
        <div style="width: 500px; height: 230px">
          <dv-charts :option="option3" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: "HealthUserData",
  
    data() {
      return {
        showChart1: false,
        option1: {
          title: {
            text: "日常在线用户率",
            offset: [-130, -20],
            style: {
              fill: "#fff",
              fontSize: 17,
              fontWeight: "normal",
              textAlign: "center",
              textBaseline: "bottom",
            },
          },
          grid: {
            left: "10%",
            top: "19%",
            right: "10%",
            bottom: "10%",
          },
          xAxis: {
            boundaryGap: false,
            axisLine: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisTick: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisLabel: {
              style: {
                fill: "#fff",
                fontSize: 10,
                rotate: 0,
              },
            },
            data: ["12-20", "12-21", "12-26"],
          },
          yAxis: {
            axisLine: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisTick: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisLabel: {
              style: {
                fill: "#fff",
                fontSize: 10,
                rotate: 0,
              },
            },
            data: "value",
          },
          series: [
            {
              data: [1, 1, 1],
              smooth: true,
              type: "line",
              lineStyle: {
                lineWidth: 3,
              },
              linePoint: {
                radius: 4,
                style: {
                  fill: "#99CCFF",
                  lineWidth: 1,
                },
              },
              lineArea: {
                show: true,
                gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
              },
            },
          ],
        },
        option2: {
          title: {
            text: "注册用户增长率",
            offset: [-130, -20],
            style: {
              fill: "#fff",
              fontSize: 17,
              fontWeight: "normal",
              textAlign: "center",
              textBaseline: "bottom",
            },
          },
          grid: {
            left: "10%",
            top: "19%",
            right: "10%",
            bottom: "10%",
          },
          xAxis: {
            boundaryGap: false,
            axisLine: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisTick: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisLabel: {
              style: {
                fill: "#fff",
                fontSize: 10,
                rotate: 0,
              },
            },
            data: ["12-20", "12-21", "12-22", "12-23", "12-24","12-25","12-26","12-27"],
          },
          yAxis: {
            axisLine: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisTick: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisLabel: {
              style: {
                fill: "#fff",
                fontSize: 10,
                rotate: 0,
              },
            },
            data: "value",
          },
          series: [
            {
              data: [100, 100, 100, 100, 100,100,200,-33],
              smooth: true,
              type: "line",
              lineStyle: {
                color: "red",
                lineWidth: 3,
              },
              linePoint: {
                radius: 4,
                style: {
                  fill: "#00D98B",
                  lineWidth: 1,
                },
              },
              lineArea: {
                show: true,
                gradient: ["rgba(0, 217, 139, 0.6)", "rgba(0, 217, 139, 0)"],
              },
            },
          ],
        },
        option3: {
          title: {
            text: "付费会员增长率",
            offset: [-130, -20],
            style: {
              fill: "#fff",
              fontSize: 17,
              fontWeight: "normal",
              textAlign: "center",
              textBaseline: "bottom",
            },
          },
          grid: {
            left: "10%",
            top: "19%",
            right: "10%",
            bottom: "10%",
          },
          xAxis: {
            boundaryGap: false,
            axisLine: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisTick: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisLabel: {
              style: {
                fill: "#fff",
                fontSize: 10,
                rotate: 0,
              },
            },
            data: ["18号", "19号", "20号", "21号", "今日"],
          },
          yAxis: {
            axisLine: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisTick: {
              style: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            axisLabel: {
              style: {
                fill: "#fff",
                fontSize: 10,
                rotate: 0,
              },
            },
            data: "value",
          },
          series: [
            {
              data: [1200, 2230, 1900, 2100, 3500],
              smooth: true,
              type: "line",
              lineStyle: {
                lineWidth: 3,
              },
              linePoint: {
                radius: 4,
                style: {
                  fill: "#99CCFF",
                  lineWidth: 1,
                },
              },
              lineArea: {
                show: true,
                gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
              },
            },
          ],
        },
      };
    },
  
    mounted() {
      // 在组件挂载后，发起网络请求
      this.fetchData();
    },
  
    methods: {
      fetchData() {
  // 发起网络请求
  axios.get('https://health.triplemaster.com/mgmt/statistics/bigscreen/user/online')
    .then(response => {
      const data = response.data.data.dateStat;

      // 日志输出，确保数据正确更新
      console.log('Fetched data:', data);

      // 将数据更新到图表的 option 中
      this.option1.xAxis.data = data.map(item => item.date);
      this.option1.series[0].data = data.map(item => item.amount);
      console.log(this.option1.series[0].data)
      // 显示图表
      this.showChart1 = true;
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
},
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 10px;
    }
  
    .echart-wrap {
      .g-tit {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  </style>
  