<template>
    <div class="main">
      <p class="tit" style="color: #D9EFFF;">覆盖量统计</p>
      <div class="content">
        <div class="data-ball ball1">
          <p>预计自营门店</p>
          <p class="num">280</p>
        </div>
        <div class="data-ball ball2">
          <p>预计自营门店</p>
          <p class="num">42,037,211</p>
        </div>
        <div class="data-ball ball3">
          <p>预计自营门店</p>
          <p class="num">200</p>
        </div>
        <div class="data-ball ball4">
          <p>预计加盟</p>
          <p class="num">1,617</p>
        </div>
        <div class="data-ball ball5">
          <p>预计自营门店</p>
          <p class="num">1,617</p>
        </div>
        <div class="data-ball ball6">
          <p>预计自营门店</p>
          <p class="num">1,617</p>
        </div>
        <div class="data-ball ball7">
          <p>预计自营门店</p>
          <p class="num">1,617</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HealthCoverageStatistics",
  
    data() {
      return {};
    },
  
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 47px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
    }
    .content {
      width: 100%;
      height: 100%;
      position: relative;
      .data-ball {
        position: absolute;
        border-radius: 50%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .num {
          font-size: 16px;
        }
      }
      .ball1 {
        width: 100px;
        height: 100px;
        background: #e35d68;
        left: 100px;
        top: 30px;
        // animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        animation: moveVertical 2s linear infinite; 
        p {
          font-size: 14px;
          color: #fff;
          margin: 2px 0;
        }
      }
      @keyframes moveHorizontal {
        0% {
          transform: translateX(0); /* 开始位置 */
        }
        50% {
          transform: translateX(20px); /* 中间位置，可以根据需要调整距离 */
        }
        100% {
          transform: translateX(0); /* 结束位置，回到原点 */
        }
      }

      /* 或者垂直移动动画 */
      @keyframes moveVertical {
        0% {
          transform: translateY(0); /* 开始位置 */
        }
        50% {
          transform: translateY(10px); /* 中间位置，可以根据需要调整距离 */
        }
        100% {
          transform: translateY(0); /* 结束位置，回到原点 */
        }
      }
      .ball2 {
        width: 140px;
        height: 140px;
        background: #8ac951;
        left: 310px;
        top: 30px;
        animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        // animation: moveVertical 2s linear infinite; 
        p {
          font-size: 18px;
          color: #fff;
          margin: 2px 0;
        }
      }
      .ball3 {
        width: 110px;
        height: 110px;
        background: #228ffe;
        right: 150px;
        top: 25px;
        // animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        animation: moveVertical 2s linear infinite; 
        p {
          font-size: 14px;
          color: #fff;
          margin: 2px 0;
        }
      }
      .ball4 {
        width: 100px;
        height: 100px;
        background: #069fc5;
        left: 120px;
        bottom: 45px;
        // animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        animation: moveVertical 2s linear infinite; 
        p {
          font-size: 14px;
          color: #fff;
          margin: 2px 0;
        }
      }
      .ball5 {
        width: 110px;
        height: 110px;
        background: #e18310;
        left: 310px;
        bottom: 10px;
        //  animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        animation: moveVertical 2s linear infinite; 
        p {
          font-size: 14px;
          color: #fff;
          margin: 2px 0;
        }
      }
      .ball6 {
        width: 110px;
        height: 110px;
        background: #08ba79;
        left: 500px;
        bottom: 55px;
        // animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        animation: moveVertical 2s linear infinite; 
        p {
          font-size: 14px;
          color: #fff;
          margin: 2px 0;
        }
      }
      .ball7 {
        width: 110px;
        height: 110px;
        background: #e18310;
        right: 50px;
        bottom: 75px;
        // animation: moveHorizontal 2s linear infinite; /* 水平移动 */
        /* 或者使用下面的垂直移动动画 */
        animation: moveVertical 2s linear infinite; 
        p {
          font-size: 14px;
          color: #fff;
          margin: 2px 0;
        }
      }
    }
  }
  </style>

  