<template>
    <div class="main">
      <p class="tit" style="color: #D9EFFF;">用户统计</p>
      <div class="content">
        <div class="group-flex mb40">
          <div>
            <p class="data-tit">总登录人次（次）</p>
            <p class="data-val">{{ userData.registerAmount }}</p>
          </div>
          <div>
            <p class="data-tit">服务时长累计（分）</p>
            <p class="data-val">{{ userData.serviceAmount }}</p>
          </div>
          <div>
            <p class="data-tit">人均服务人次（人）</p>
            <p class="data-val">{{ userData.serviceTimes }}</p>
          </div>
        </div>
        <div class="group-flex">
          <div class="d-flex algin-end">
            <div>
              <p class="mb26">同比上周</p>
              <div class="d-flex">
                <img
                  src="../../assets/imgs/arrow-up.png"
                  width="24px"
                  height="24px"
                  alt=""
                />
                <p class="number-up ml8">{{ userData.registerAmountRatio }}%</p>
              </div>
            </div>
            <div class="line-data-wrap">
              <div
                v-for="(v, i) in weekData1.data"
                :key="i"
                :style="{
                  background: weekData1.up ? '#25D94B' : '#FC743E',
                  height: v * 6 + 'px',
                }"
                class="line-item"
              ></div>
            </div>
          </div>
          <div class="d-flex algin-end">
            <div>
              <p class="mb26">同比上周</p>
              <div class="d-flex algin-center">
                <img
                  src="../../assets/imgs/arrow-up.png"
                  width="24px"
                  height="24px"
                  alt=""
                />
                <p class="number-up ml8">{{ userData.serviceAmountRatio }}%</p>
              </div>
            </div>
            <div class="line-data-wrap">
              <div
                v-for="(v, i) in weekData2.data"
                :key="i"
                :style="{
                  background: weekData2.up ? '#25D94B' : '#FC743E',
                  height: v * 6 + 'px',
                }"
                class="line-item"
              ></div>
            </div>
          </div>
          <div class="d-flex algin-end">
            <div>
              <p class="mb26">同比上周</p>
              <div class="d-flex algin-center">
                <img
                  src="../../assets/imgs/arrow-down.png"
                  width="24px"
                  height="24px"
                  alt=""
                />
                <p class="number-down ml8">{{ userData.serviceTimesRation }}%</p>
              </div>
            </div>
            <div class="line-data-wrap">
              <div
                v-for="(v, i) in weekData3.data"
                :key="i"
                :style="{
                  background: weekData3.up ? '#25D94B' : '#FC743E',
                  height: v * 6 + 'px',
                }"
                class="line-item"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HealthServiceStatistics",
  
    data() {
      return {
        userData: {
        // 初始化数据，避免模板渲染时出错
        registerAmount: 0,
        serviceAmount: 0,
        serviceTimes: 0,
      },
        weekData1: {
          up: true,
          data: [5, 8, 10, 8, 10, 6, 8, 9, 10, 10, 10],
          max: 300,
        },
        weekData2: {
          up: true,
          data: [5, 8, 10, 8, 10, 6, 8, 9, 10, 10, 10],
          max: 300,
        },
        weekData3: {
          up: false,
          data: [5, 8, 10, 8, 10, 6, 8, 9, 10, 10, 10],
          max: 300,
        },
      };
    },
  
    mounted() {
    // 在组件挂载后，请求接口并更新数据
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        // 发送 GET 请求获取数据
        const response = await this.$axios.get(
          "https://health.triplemaster.com/mgmt/statistics/bigscreen/general"
        );

        // 假设接口返回的数据格式如下
        const data = response.data.data;

        // 更新userData的值
        this.userData = data;
      } catch (error) {
        console.error("接口请求失败", error);
      }
    },
  },
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 47px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 40px;
      text-align: center;
    }
  
    .group-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        flex: 1;
      }
      .data-tit {
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 36px;
      }
  
      .data-val {
        font-size: 50px;
        color: #ffffff;
        // font-weight: bold;
        line-height: 1;
      }
      .number-up {
        color: #25d94b;
        font-size: 24px;
        font-weight: bold;
        height: 24px;
        line-height: 24px;
      }
      .number-down {
        color: #fc743e;
        font-size: 24px;
        font-weight: bold;
        height: 24px;
        line-height: 24px;
      }
      .line-data-wrap {
        margin-left: 20px;
        height: 60px;
        display: flex;
        align-items: end;
        .line-item {
          width: 8px;
          height: 100%;
          margin-right: 5px;
          border-radius: 50px;
        }
      }
    }
  }
  </style>
  